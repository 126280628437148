import React, { useState } from 'react';
import WITCardInput from '../WITCardInput';
import Loader from '../Loader';
import SuccessView from '../SuccessVIew'
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Badge, Alert } from 'reactstrap';
import { stepNavigation, setActiveReservations, requestBedroomInfo, raiseValidationError,clearErrorState} from './app.single-state-reducer'
import * as constants from '../constants';
import {loadStaticData} from '../service'
import moment from 'moment';
import Session from '../Session';
class App extends React.Component {
    state = {
        reservation: null,
        requestParams: {},
        isLoading: false,
        view: '',
        validations: {},
        cardServerResponse: {},
        staticData:{}
    }
    isTestMode = false;
    sessionStore = new Session();
    inputParams = {
        RefNum: '',
        RsvnDtl: '0',
        LastName: '',
        RecType:'R',
        PropertyCode: ""
    }
    thankYouMessage="Thank You, your booking now is guaranteed.";
    cardParameter = {
        GuarantyCard: {
            CardType: '',
            IssueDate: '',
            ExpDate: '',
            HolderName: '',
            CCNumber: '',
            CvvNum: ''
        },
        Address1: '',
        Address2: '',
        City: '',
        Country: '',
        PostCode: '',
        ContactNo: '',
        Remarks:'',
        Email:'',
        GuestName:''
    }
    componentDidMount(){
        loadStaticData(this);
    }
    raiseValidationState = (params, ref) => {
        let validations = Object.assign({}, params, ref);
        return validations;
    }
    handleCardValidation = () => {
        let ref = this;
        let validations = {};
        if (ref.cardParameter.GuarantyCard.HolderName.trim().length <= 0) {
            validations = this.raiseValidationState({ HolderName: { error: true, errorText: 'Please enter card holder name.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ HolderName: { error: false, errorText: '' } }, validations)
        }

        if (!ref.cardParameter.GuarantyCard.CardType || ref.cardParameter.GuarantyCard.CardType == '' || !ref.cardParameter.GuarantyCard.CCNumber || ref.cardParameter.GuarantyCard.CCNumber == '') {
            validations = this.raiseValidationState({ cc: { error: true, errorText: 'Please enter valid card details.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ cc: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.GuarantyCard.CvvNum.trim().length <= 0) {
            validations = this.raiseValidationState({ CVV: { error: true, errorText: 'Please enter cvv number.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ CVV: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.GuarantyCard.ExpDate.trim().length <= 0) {
            validations = this.raiseValidationState({ Expiry: { error: true, errorText: 'Please enter valid expiry date (MM/YY).' } }, validations)
        }
        else {
            
            if(moment(this.state.staticData.ServerDate,'DDMMMYYYY',false).isAfter(moment(ref.cardParameter.GuarantyCard.ExpDate.trim(),'DDMMMYYYY',false))){
                validations = this.raiseValidationState({ Expiry: { error: true, errorText: 'Please enter valid expiry date (MM/YY).' } }, validations)    
            }
            else
                validations = this.raiseValidationState({ Expiry: { error: false, errorText: '' } }, validations)
        }

        if (ref.cardParameter.Address1.trim().length <= 0) {
            validations = this.raiseValidationState({ Address: { error: true, errorText: 'Please enter address 1.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ Address: { error: false, errorText: '' } }, validations)
        }

        if (ref.cardParameter.City.trim().length <= 0) {
            validations = this.raiseValidationState({ City: { error: true, errorText: 'Please enter City.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ City: { error: false, errorText: '' } }, validations)
        }
       
        if (ref.cardParameter.Country.trim().length <= 0) {
            validations = this.raiseValidationState({ Country: { error: true, errorText: 'Please enter country.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ Country: { error: false, errorText: '' } }, validations)
        }

        if (ref.cardParameter.PostCode.trim().length <= 0) {
            validations = this.raiseValidationState({ PostCode: { error: true, errorText: 'Please enter postcode.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ PostCode: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.ContactNo.trim().length <= 0) {
            validations = this.raiseValidationState({ ContactNo: { error: true, errorText: 'Please enter contact no.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ ContactNo: { error: false, errorText: '' } }, validations)
        }

        if (ref.cardParameter.Email.trim().length > 0) {
            
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var testValue=regex.test(ref.cardParameter.Email.trim());
            if(!testValue)
                validations = this.raiseValidationState({ Email: { error: true, errorText: 'Please enter valid email.' } }, validations);
            else
                validations = this.raiseValidationState({ Email: { error: false, errorText: '' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ Email: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.GuestName.trim().length <= 0) {
            validations = this.raiseValidationState({ GuestName: { error: true, errorText: 'Please enter guest name.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ GuestName: { error: false, errorText: '' } }, validations)
        }
        let hasError = false;
        if (validations) {
            for (let key in validations) {
                if (validations[key].error) {
                    hasError = true;
                    break;
                }
            }
        }
        return { hasError, validations };
        //raiseValidationError(validations,ref);

    }
    hasCardError = () => {
        let hasError = false;
        if (this.state.validations) {
            for (let key in this.state.validations) {
                if (this.state.validations[key].error) {
                    hasError = true;
                    break;
                }
            }
        }
        return hasError;
    }
    checkAnyReservationSelected() {
        return this.state.reservation.Reservations.filter(p => p.selected).length > 0;
    }
    handleChangeValidation = () => {
        if (this.hasCardError()) {
            this.handleCardValidation();
        }
    }
    getSelectedReservationDins() {
        let RsvnDtlArray = [];
        for (var i = 0; i < this.state.reservation.Reservations.length; i++) {
            if (this.state.reservation.Reservations[i].selected) {
                RsvnDtlArray.push(this.state.reservation.Reservations[i].RsvnDtl);
            }
        }
        return RsvnDtlArray.join(',');
    }
    isSavedinCurrentSession(item) {
        let items = this.sessionStore.get("SAVED");
        if (items && items.length > 0) {
            let data = items.filter(p => p.RsvnNum === item.RsvnNum && p.RsvnDtl === item.RsvnDtl);
            return (data && data.length > 0)
        }
        return false;
    }
    render() {
        return <div className="container-fluid">
            {this.state.isLoading && <Loader></Loader>}
            <div className="row">
                <main className="main-content col-lg-12 col-md-12 col-sm-12 p-0 ">
                    <div className="main-navbar sticky-top bg-white d-flex align-items-center justify-content-center">                       
                        <img src="/Logo.png" style={{width:'70px',margin:'0.5rem'}}></img>
                    </div>
                    <div className="main-content-container container-fluid px-4">
                        <div className="row ">
                            <div className="col-12">
                                <div className="container p-3 d-flex justify-content-center flex-column w-100">
                                    {(this.state.view === "" || this.state.view === constants.RESERVATION_VIEW) && renderSearchView(this, this.inputParams)}
                                    {this.state.view === constants.RESERVATION_VIEW && renderReservationInfo(this)}
                                    {this.state.view === constants.CARD_VIEW && renderCardView(this)}
                                    {this.state.view === constants.FINAL_VIEW && SuccessView(this)}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </div>
    }
}

function renderSearchView(props, params) {
    return <form autoComplete="off" ref={ref=>props.SearchForm=ref}><div className="row align-items-center justify-content-center">
        <div className="col-lg-auto col-md-auto">
            <span>Hotel</span>
        </div>
        
        <div className="col-lg-auto col-md-auto">
           <select className="form-control" required
           title=""
           onInvalid={e=>{
              e.target.setCustomValidity('Select Hotel')
           }}
           onInput={e=>{
              e.target.setCustomValidity('')
           }}
           
           onChange={e=>{              
               clearErrorState(props); params.PropertyCode = e.target.value;
           }}>
             <option value="">Select Hotel</option>
            {props.state.staticData&&props.state.staticData.Hotels&&props.state.staticData.Hotels.map((item,index)=>{
            return <option key={index} value={item.PropertyCode}>{item.PropName}</option>
            })}
           </select>
            
        </div>
        <div className="col-lg-auto col-md-auto">
            <span>Reference No</span>
        </div>
        
        <div className="col-lg-auto col-md-auto">
           
            <input type="text"
            maxLength="25"
            title=""
             onInvalid={e=>{
                e.target.setCustomValidity('Enter Reference No')
             }}
             onInput={e=>{
                e.target.setCustomValidity('')
             }}
            className="form-control" required name="ReservationNo" onChange={e => {  clearErrorState(props); params.RefNum = e.target.value }} placeholder="Reference No" />
        </div>
        {/* <div className="col">
            <input type="text" className="form-control" name="DetailItemNo" onChange={e => { params.RsvnDtl = e.target.value }} placeholder="Detail Item No" />
        </div> */}
        <div className="col-lg-auto col-md-auto">
            <span>Last Name</span>
        </div>
        <div className="col-lg-auto col-md-auto">
          
            <input type="text" 
             title=""            
             onInvalid={e=>{
                e.target.setCustomValidity('Enter Last Name')
             }}
             onInput={e=>{
                e.target.setCustomValidity('')
             }}
            className="form-control" required name="LastName" onChange={e => { clearErrorState(props); params.LastName = e.target.value }} placeholder="Last name" />
        </div>
        <div className="col-lg-auto col-md-auto p-3">
        
            <button type="button" onClick={e => {
                
                if(props.SearchForm.checkValidity())
                {
                    requestBedroomInfo(params, props);
                }
                else{
                    props.SearchForm.reportValidity()
                }
                
            }} class="btn btn-primary">Search</button>
        </div>
    </div>
    </form>
}
function renderCardView(ref) {
    return <div className="row align-items-center justify-content-center ">
        <div className="col-md-10 col-lg-8 col-sm-12 col-xs-12">
            <div className="container ">
            <form autoComplete="off" >
                <div className="card card-small user-details mb-4">
                    <div className="card-header p-1 text-center">
                        {ref.state.cardServerResponse && ref.state.cardServerResponse.Status && ref.state.cardServerResponse.Status.toUpperCase != "SUCCESS" && <span className="text-danger">Failed ! Please try again.</span>}
                    </div>
                    <div className="card-body pt-1">
                        <h4 className="text-center m-0 mt-2">Guarantee Information</h4>
                        <p className="text-center text-light m-1 mb-2">{ref.state.reservation.Reservations[0].RsvnNum}/{ref.getSelectedReservationDins()}</p>
                        <form className="mt-3" >
                            <div className="form-group">
                                <label >Card Holder</label>
                                <input type="text" className="form-control" onChange={e => {
                                    ref.cardParameter.GuarantyCard.HolderName = e.target.value;
                                    ref.handleChangeValidation();
                                }} autoComplete="off"  maxLength="100"  placeholder="Card Holder" />
                                {ref.state.validations.HolderName && ref.state.validations.HolderName.error && <span className="text-danger">{ref.state.validations.HolderName.errorText}</span>}
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label >Card No</label>
                                    <WITCardInput onChange={e => {
                                        ref.cardParameter.GuarantyCard.CardType = e.type;
                                        ref.cardParameter.GuarantyCard.CCNumber = e.value;
                                        ref.handleChangeValidation();
                                    }} />
                                    {ref.state.validations.cc && ref.state.validations.cc.error && <span className="text-danger">{ref.state.validations.cc.errorText}</span>}
                                </div>

                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label >Expiry</label>
                                    <input type="text" className="form-control" 
                                        onChange={e => {
                                            if (e.target.validity.valid) {
                                                let data = moment(e.target.value, 'MMYY', false);
                                                if (data.isValid())
                                                    ref.cardParameter.GuarantyCard.ExpDate = data.format('DD MMM YYYY');// data.format('DD MMM YYYY');
                                                else
                                                    ref.cardParameter.GuarantyCard.ExpDate = "";
                                            }
                                            else
                                                ref.cardParameter.GuarantyCard.ExpDate = '';

                                            ref.handleChangeValidation();
                                        }}
                                        autoComplete="disabled" pattern="\d{1,2}/\d{1,2}" placeholder="MM/YY" />
                                    {ref.state.validations.Expiry && ref.state.validations.Expiry.error && <span className="text-danger">{ref.state.validations.Expiry.errorText}</span>}
                                </div>
                                <div class="form-group col-md-6">
                                    <label >CVV</label>
                                    <input type="password" className="form-control"
                                        onChange={e => {


                                            ref.cardParameter.GuarantyCard.CvvNum = e.target.value;
                                            ref.handleChangeValidation();
                                        }}
                                         autoComplete="disabled" placeholder="CVV" maxLength="4" />
                                    {ref.state.validations.CVV && ref.state.validations.CVV.error && <span className="text-danger">{ref.state.validations.CVV.errorText}</span>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label >Address</label>
                                <input type="text" className="form-control"
                                    maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.Address1 = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                     autoComplete="disabled" placeholder="1234 Main St" />
                                {ref.state.validations.Address && ref.state.validations.Address.error && <span className="text-danger">{ref.state.validations.Address.errorText}</span>}
                            </div>
                            <div className="form-group">
                                <label >Address 2</label>
                                <input type="text" className="form-control"
                                    maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.Address2 = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                     autoComplete="disabled" placeholder="Apartment, studio, or floor" />

                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <label >City</label>
                                    <input type="text" className="form-control"
                                     maxLength="200"
                                        onChange={e => {
                                            ref.cardParameter.City = e.target.value;
                                            ref.handleChangeValidation();
                                        }}
                                        autoComplete="disabled" />
                                    {ref.state.validations.City && ref.state.validations.City.error && <span className="text-danger">{ref.state.validations.City.errorText}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label >Country</label>
                                    <input type="text" className="form-control"
                                     maxLength="100"
                                        onChange={e => {
                                            ref.cardParameter.Country = e.target.value;
                                            ref.handleChangeValidation();
                                        }}
                                         autoComplete="disabled" />
                                    {ref.state.validations.Country && ref.state.validations.Country.error && <span className="text-danger">{ref.state.validations.Country.errorText}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label>Postcode</label>
                                    <input type="text"
                                     
                                        onChange={e => {
                                            ref.cardParameter.PostCode = e.target.value;
                                            ref.handleChangeValidation();
                                        }}
                                        className="form-control" autoComplete="disabled" maxLength="25"  />
                                    {ref.state.validations.PostCode && ref.state.validations.PostCode.error && <span className="text-danger">{ref.state.validations.PostCode.errorText}</span>}
                                </div>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-6">
                                <label >Contact No</label>
                                <input type="text" className="form-control"
                                    maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.ContactNo = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                     autoComplete="disabled" placeholder="Contact Number" />
                                {ref.state.validations.ContactNo && ref.state.validations.ContactNo.error && <span className="text-danger">{ref.state.validations.ContactNo.errorText}</span>}
                            </div>
                            <div className="form-group col-md-6">
                                <label >Email</label>
                                <input type="text" className="form-control"
                                    maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.Email = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                     autoComplete="disabled" placeholder="Email" />
                                {ref.state.validations.Email && ref.state.validations.Email.error && <span className="text-danger">{ref.state.validations.Email.errorText}</span>}
                            </div>
                            </div>
                            <div className="form-group">
                                <label >Guest Name</label>
                                <input type="text" className="form-control"
                                    maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.GuestName = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                     autoComplete="disabled" placeholder="Mr. John Doe" />
                                {ref.state.validations.GuestName && ref.state.validations.GuestName.error && <span className="text-danger">{ref.state.validations.GuestName.errorText}</span>}
                            </div>
                            
                            <div className="form-group">
                                <label for="inputAddress2">Remarks</label>
                                <textarea  
                                rows="2"
                                maxLength="1000"
                                className="form-control"
                                  onChange={e => {
                                    ref.cardParameter.Remarks = e.target.value;
                                    ref.handleChangeValidation();
                                }}
                                autoComplete="disabled" placeholder="Remarks"
                                >

                                </textarea>                              
                               
                            </div>
                            <div className="row align-items-center justify-content-center">
                                <button type="button" class="m-2 btn btn-warning " onClick={e => { stepNavigation('RESERVATIONLIST', ref) }} >Back</button>
                                <button class="m-2 btn btn-sm btn-info mr-1" type="button" onClick={e => {
                                    let status = ref.handleCardValidation();
                                    if (status.hasError) {
                                        raiseValidationError(status.validations, ref);
                                    }
                                    else {
                                        stepNavigation('FINAL', ref)
                                    }
                                }}>Continue</button>
                            </div>
                        </form>
                    </div>
                </div>
            </form>
            </div>
        </div>
    </div>
}
function renderErrorView(){
    return  <div className="alert alert-warning rounded m-5" role="alert">
                    <h4 className="alert-heading">Error</h4>
                    <hr></hr>
                    <p>No Records.</p>
            </div>
}
function renderReservation(item, ref) {
    return <ListGroup className="mt-1">
        <ListGroupItem >
            <ListGroupItemHeading>
                <Badge color='primary'>{item.RsvnNum}/{item.RsvnDtl}</Badge>      {item.GuestFullName}
              
                <button type="button"
                    className={item.selected ? 'mb-2 btn btn-xs btn-success mr-2 float-right' : 'mb-2 btn btn-xs btn-info mr-2  float-right'}
                    onClick={e => {
                        setActiveReservations({ RsvnNum: item.RsvnNum, RsvnDtl: item.RsvnDtl, active: !item.selected }, ref);
                    }
                    } >{item.selected ? 'Selected' : 'Select'}</button>
                      {ref.isSavedinCurrentSession(item) && <Badge className="mr-2 float-right" color="success">Payment Details Updated</Badge>}

            </ListGroupItemHeading>
            <ListGroupItemText>
                <div className="user-details__user-data border-top border-bottom p-4">
                    <div className="row mb-3">
                        <div className="col-auto">
                            <span className="font-weight-bold"> Total reservation value:</span>
                            <span>  &pound;{item.ReservationValue} (incl VAT)</span>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-auto">
                            <span className="font-weight-bold">Arrival:</span>
                            <span>  {item.Arrival}</span>
                        </div>
                        <div className="col-auto">
                            <span className="font-weight-bold">Departure:</span>
                            <span>  {item.Departure}</span>
                        </div>
                    </div>
                    {
                       item.Terms&&item.Terms.length>0 && <div className="row">
                        <div className="col-12">
                            <span className="font-weight-bold">Inclusions</span>
                        </div>
                        <div className="col-12">
                            <ul className="p-0 m-0" style={{ listStyle: 'none' }}>
                                { item.Terms.map((item, index) => {
                                return <li key={index} style={{ float: 'left', paddingLeft: '1rem', paddingRight: '1rem' }} className="border-right d-flex align-items-center justify-content-center">{item.ItemDate},{item.ItemDesc}({item.ItemQty})</li>
                                })}

                            </ul>
                        </div>
                        </div>
                    }
                   {
                       item.Extras&&item.Extras.length>0 && <div className="row">
                        <div className="col-12">
                            <span className="font-weight-bold">Extras</span>
                        </div>
                        <div className="col-12">
                            <ul className="p-0 m-0" style={{ listStyle: 'none' }}>
                                { item.Extras.map((item, index) => {
                                return <li key={index} style={{ float: 'left', paddingLeft: '1rem', paddingRight: '1rem' }} className="border-right d-flex align-items-center justify-content-center">{item.ItemDate},{item.ItemDesc}({item.ItemQty})  {item.ItemPrice}</li>
                                })}

                            </ul>
                        </div>
                        </div>
                    }

                </div>
            </ListGroupItemText>
            <div>

            </div>
        </ListGroupItem>
    </ListGroup>
}
function renderReservationInfo(ref) {
    if(ref.state.reservation==null||(ref.state.reservation.Status&&ref.state.reservation.Status.toUpperCase()==="FAILED")){
        return renderErrorView();
    }
    return <div className="row p-2 justify-content-center">
        <div class="col-12 d-flex align-items-center justify-content-center">
            {
                ref.checkAnyReservationSelected() && < button type = "button"
                class = "mb-2 btn btn-info mt-5"
                onClick = {
                    e => {
                        stepNavigation('PAYMENT', ref)
                    }
                } > Continue to Guarantee </button>}
        </div>
        <div class="col-12">
            {
                ref.state.reservation.Reservations.map((item, index) => {
                    return renderReservation(item, ref)
                })
            }
        </div>
        <div class="col-12 d-flex align-items-center justify-content-center">
            {
                ref.checkAnyReservationSelected() && < button type = "button"
                class = "mb-2 btn btn-info  mt-3"
                onClick = {
                    e => {
                        stepNavigation('PAYMENT', ref)
                    }
                } > Continue to Guarantee </button>}
        </div>
    </div>
}
export default App;
