import * as constants from '../constants';

const dispatch = (ref, action) => {

    switch (action.type) {
        case constants.requestBedroomDetails: return {
            ...ref.state,
            reservation:null,
            requestParams: action.params,
            isLoading: true
        };
        case constants.requestCardDetails: return {
            ...ref.state,
            isLoading: true
        };
        case constants.receiveBedroomDetails: return {
            ...ref.state,
            reservation: action.reservation,
            view:constants.RESERVATION_VIEW,
            isLoading: false
        };
        case constants.responseSetActiveReservation: return {
            ...ref.state,
            reservation: action.reservation,
            view: constants.RESERVATION_VIEW,
            isLoading: false
        };
        case constants.requestPayment: return {
            ...ref.state,            
            view: action.view,
            isLoading: false
        };       
        case constants.receiveCardDetails: return {
            ...ref.state,            
            cardServerResponse: action.response,
            reservation:{},
            view: action.view,
            isLoading: false
        };     
        case constants.errorBedroomDetails: return {
            ...ref.state,
            reservation: {Status:'Failed'},
            view: constants.RESERVATION_VIEW,
            isLoading: false
        };
        case constants.clearBedRoomListError: return {
            ...ref.state,
            reservation: {Status:''},
            view: '',
            isLoading: false
        };
        case constants.errorCardService: return {
            ...ref.state,
            cardServerResponse: action.response,
            view: action.view,
            isLoading: false
        };
        case constants.requestValidations: return {
            ...ref.state,          
            validations: action.validations,
            isLoading: false
        };
        case constants.requestStaticData: return {
            ...ref.state,
            isLoading: true
        };
        case constants.receiveStaticData: return {
            ...ref.state,
            staticData:action.response,
            isLoading: false
        };
        case constants.backToView: return {
            ...ref.state,          
            view: action.view,
            isLoading: false
        };
        default: return ref.state;
    }    
};

export {dispatch};