import {dispatch} from '../dispatcher'
import * as constants from '../constants'
const clearErrorState=( ref)=>{
  
    let localState = ref.state;
    let reservation = ref.state.reservation;
    if(reservation&&reservation.Status&&reservation.Status.toUpperCase()==="FAILED"){
        ref.state.reservation.Status="";
        localState = dispatch(ref.state, { type: constants.clearBedRoomListError });
        ref.setState({ ...localState });
    }
}
const raiseValidationError=(params, ref)=>{
    let localState = ref.state;
    let validations=Object.assign({},ref.state.validations,params);
    localState = dispatch(ref.state, { type: constants.requestValidations, validations });
    ref.setState({ ...localState });
}
export {clearErrorState,raiseValidationError}