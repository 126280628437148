import { dispatch } from '../dispatcher';
import * as constants from '../constants';
import reservationsData from '../reservation-data.json';
import Session from '../Session';
import {clearErrorState,raiseValidationError} from '../reducer'
import {ApiService} from '../service'
const stepNavigation = async(params, ref) => {
    let localState = ref.state;
    
    if (params === "PAYMENT")
    {
        localState = dispatch(ref.state, { type: constants.requestPayment, view: constants.CARD_VIEW });
        ref.setState({ ...localState });
    }
    else if (params === "RESERVATIONLIST")
    {
        localState = dispatch(ref.state, { type: constants.backToView, view: constants.RESERVATION_VIEW });
        ref.setState({ ...localState });
    }
    else if (params === "FINAL")
    {
        localState=dispatch(ref,{ type: constants.requestCardDetails });  
        ref.setState({ ...localState });
        let {PropertyCode,RefNum,RecType}=ref.inputParams;
        let RsvnDtlArray=[];
        let reservation = ref.state.reservation;
        let {LastDate,EarlyDate}=reservation.Reservations[0];
        let requestParams=Object.assign({},{PropertyCode,RefNum,RecType,RsvnDtl:"0",Arrival:EarlyDate,Departure:LastDate},ref.cardParameter);
        let apiRequest={RsvnGrntyDetails:[]};
        apiRequest.RsvnGrntyDetails.push(requestParams);
       
        if(ref.isTestMode){        
            localState=dispatch(ref,{ type: constants.receiveCardDetails,  response:{Status:'Success'},view: constants.FINAL_VIEW });
            new Session().append("SAVED-GP",reservation.Reservations.filter(p=>p.selected));
            ref.setState({ ...localState });

            return;
        }

        ApiService("UpdateGuaranty", apiRequest, (data) => {
            let response = data || {Status:'Failed'};
            let view= constants.FINAL_VIEW;
            if(response.Status.toUpperCase()!="SUCCESS")
                view= constants.CARD_VIEW;
            localState=dispatch(ref,{ type: constants.receiveCardDetails, response,view: view });            
            ref.setState({ ...localState });

        }, (error) => {
            localState=dispatch(ref,{ type: constants.errorCardService, response:{Status:'Failed'},view: constants.CARD_VIEW  });
            ref.setState({ ...localState });
        });   
     
        
    }        
   
}

const setActiveReservations = (param,ref) => {
    let localState = ref.state;
    let reservation = ref.state.reservation;
    for (var i = 0; i < reservation.Reservations.length; i++) {
        if (reservation.Reservations[i].GroupId === param.GroupId ) {
            reservation.Reservations[i].selected = param.active;
           break;
        }
    }
    localState = dispatch(ref.state, { type: constants.responseSetActiveReservation, reservation: reservation });
    ref.setState({ ...localState });
}

const requestBedroomInfo = async(params, ref) => {
    
    let localState = ref.state;
    localState=dispatch(ref,{ type: constants.requestBedroomDetails, params });
    ref.setState({ ...localState });
    if(ref.isTestMode){   
        localState=dispatch(ref,{ type: constants.receiveBedroomDetails, reservation:JSON.parse(JSON.stringify(reservationsData)) });
        ref.setState({ ...localState });
        return;
    }
   
    ApiService("GetReservationDetails", params, (data) => {
            let reservation = data || {};
            let response = data || {
                Status: 'Failed'
            };
            let type = constants.receiveBedroomDetails;
            if (response.Status.toUpperCase() != "SUCCESS")
            type = constants.errorBedroomDetails;
            localState=dispatch(ref,{ type: type, reservation });
            ref.setState({ ...localState });

    }, (error) => {
        localState=dispatch(ref,{ type: constants.errorBedroomDetails, reservation:null});
        ref.setState({ ...localState });
    });   
 
   

}
export { stepNavigation, setActiveReservations, requestBedroomInfo,raiseValidationError,clearErrorState }