import React from 'react';
import PropTypes from 'prop-types';
import * as CommonUtils from 'util';
import $ from 'jquery';
//import {ReactComponent as generalLog } from './images/general.svg';

export default class WITCardInput extends React.Component {
    static propTypes = {
        value: PropTypes.string,
        onChange: PropTypes.func,
        name: PropTypes.string,
        disabled: PropTypes.bool,
        mandatory: PropTypes.bool,
        mask: PropTypes.bool
    }
    static defaultProps = {
        value: '',
        onChange: undefined,
        name: '',
        disabled: false,
        mandatory: false,
        mask: false
    }
    constructor(props) {
        super(props);
        let { value, mask } = this.props;
        this.state = {
            placeholderMask: 'XXXX-XXXX-XXXX-XXXX',
            inputValue: value || '',
            maskedInput: '',
            cardIcon: 'general.svg',
            cardType: '',
            disabled: false,
            mandatory: false,
            mask: mask || false
        }
        this.cardTypes = [
            { card: 'AMEX', matchString: /^3[47][0-9]{13}$/, icon: 'amex.svg', description: 'Amex Card' },
            { card: 'MASTER', matchString: /5[1-5]\d{2}-?\d{4}-?\d{4}-?\d{4}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))-?\d{4}-?\d{4}-?\d{4}$/, icon: 'mastercard.svg', description: 'Master Card' },
            { card: 'DINERS', matchString: /^3(?:0[0-5]|[68][0-9])[0-9]{11}|^54[0-9]{13}$/, icon: 'diners.svg', description: 'Diners Club Card' },
            { card: 'DISCOVER', matchString: /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/, icon: 'discover.svg', description: 'Discover Card' },
            { card: 'JCB', matchString: /(?:2131|1800|35\d{3})\d{11}/, icon: 'jcb.svg', description: 'JCB Card' },
            { card: 'MASTERO', matchString: /^(5018|5020|5038|5893|6304|6759|6761|6762|6763|6771)[0-9]{8,15}$/, icon: 'maestro.svg', description: 'Mastero Card' },
            { card: 'SOLO', matchString: /^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$/, icon: 'spotify.svg', description: 'Solo Card' },
            { card: 'SWITCH', matchString: /^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$/, icon: 'switch.svg', description: 'Switch Card' },
            { card: 'VISA', matchString: /^4[0-9]{12}(?:[0-9]{3}|[0-9]{6})?$/, icon: 'visa.svg', description: 'Visa Card' },
            { card: 'UPAY', matchString: /^(62[0-9]{14,17})$/, icon: 'unionpay.svg', description: 'Union Pay Card' },

        ];
        this.ICON_PATH = '/images/';
    }    
    componentDidMount() {
        this.doInit(this.props);
    }
    componentWillReceiveProps({ value, disabled, mandatory, mask }) {
        this.doInit({ value, disabled, mandatory, mask })
    }
    doInit({ value, disabled, mandatory, mask }) {
        let { inputValue, maskedInput, cardType, cardIcon } = this.state;
        inputValue = value || '';
        maskedInput = this.maskJoin(value || '');
        let outInfo = this.getCarInfo({ inputValue, maskedInput, cardType, cardIcon });
        this.setState({ inputValue, maskedInput, disabled, mandatory, ...outInfo, mask });

       
    }
    focus = () => {
        if (this.cardInput)
            this.cardInput.focus();
    }
    maskJoin = (val) => {
        let chunks = val.match(/.{1,4}/g);
        if (chunks) {
            let modifiedChunks = chunks.map((item, index) => {
                if (item.length < 4 && index < 4)
                    item = item.concat(" ".repeat(4 - item.length));
                if (item.length < 4 && index === 4)
                    item = item.concat(" ".repeat(3 - item.length));
                return item;
            });

            if (modifiedChunks.length < 4) {
                for (var i = 0; i < 4; i++) {

                    if (!modifiedChunks[i])
                        modifiedChunks.push(" ".repeat(4));
                }
            }
            return modifiedChunks.join("-");
        }
        return null;

    }
    getCarInfo = ({ inputValue, cardType, cardIcon }) => {
        cardType = undefined;
        cardIcon = 'general.svg';
        let val = inputValue.replace(/-/gi, "");
        this.cardTypes.forEach(item => {
            if (item.matchString.test(val)) {
                cardType = item.card;
                cardIcon = item.icon;
                return false;
            }
        });
        return { cardType, cardIcon };
    }
    validateCardType = (sendEvent) => {
        let { inputValue, cardType, cardIcon } = this.state;
        sendEvent = sendEvent || false;
        let { onChange, name } = this.props;
        cardType = undefined;
        cardIcon = 'general.svg';
        let val = inputValue.replace(/-/gi, "");
        this.cardTypes.forEach(item => {
            if (item.matchString.test(val)) {
                cardType = item.card;
                cardIcon = item.icon;
                return false;
            }
        });
        this.setState({ cardType, cardIcon });
        if (onChange && CommonUtils.isFunction(onChange) && sendEvent) {
            let maskValue = this.maskJoin(inputValue);
            onChange({ type: cardType, value: inputValue, maskedInput: maskValue, name });
        }
    }
    onClear = () => {
        let { inputValue, maskedInput, cardType, cardIcon } = this.state;
        cardType = undefined;
        cardIcon = 'general.svg';
        inputValue = '';
        maskedInput = '';
        this.updateState({ inputValue, maskedInput, cardType, cardIcon });
        let { onChange, name } = this.props;
        if (onChange && CommonUtils.isFunction(onChange)) {
            let maskValue = this.maskJoin(inputValue);
            onChange({ type: cardType, value: inputValue, maskedInput: maskValue, name });
        }
    }
    onCardInputBlur = (e) => {
        let { inputValue } = this.state;
        e.target.setCustomValidity(" test");
        this.manageInputData(inputValue, true);
    }
    onCardFocus = (e) => {
        let { inputValue } = this.state;
        e.target.setCustomValidity(" test");
        this.manageInputData(inputValue, false);
    }
    onCardInputChange = (e) => {
        let currentValue = e.target.value || '';
        e.target.setCustomValidity(" test");
        this.manageInputData(currentValue, false);
    }
    onCardInputPaste = (e) => {
        let clipboardData = e.clipboardData || window.clipboardData;
        let pastedData = clipboardData.getData('Text');
        this.manageInputData(pastedData, true);
    }
    onCardInputKeyPress = (e) => {
        if (!String.fromCharCode(e.charCode).match(/^([1-9]\d*|0)$/)) { e.preventDefault(); return true; }
    }
    onCardInput= (e) => {
        e.target.setCustomValidity(" test");
    }
    onCardInvalid= (e) => {
        e.target.setCustomValidity(" test");
    }
    updateState = (data) => {
        window.setTimeout(() => {
            this.setState({ ...data });
        }, 0);
        // this.setState({ ...data });
    }
    manageInputData = (data, triggerValidation) => {
        triggerValidation = triggerValidation || false;
        let { inputValue, maskedInput } = this.state;
        inputValue = data.replace(/-/gi, "");
        maskedInput = inputValue;
        if (triggerValidation) {
            maskedInput = this.maskJoin(inputValue);
            this.validateCardType(true);
        }

        this.updateState({ inputValue, maskedInput });
    }
    renderInput = () => {
        let { placeholderMask, maskedInput, cardIcon, disabled, mandatory } = this.state;
        let { onCardInputChange, onCardInputPaste, onCardInputBlur, onCardFocus, onCardInputKeyPress, onClear, ICON_PATH,onCardInput,onCardInvalid } = this;
        let cardInputProps = {
            type: 'text',
            ref: ref => this.cardInput = ref,
            onChange: onCardInputChange,
            onPaste: onCardInputPaste,
            onBlur: onCardInputBlur,
            onFocus: onCardFocus,
            // onInvlid:onCardInvalid,
            // onInput:onCardInput,
            maxLength: 19,
            disabled: disabled,
            pattern: "[0-9]*",
            onKeyPress: onCardInputKeyPress,
            
        };
        let __icon = `${ICON_PATH}general.svg`;
        if (cardIcon) {
            __icon = `${ICON_PATH}${cardIcon}`;
        }
        let textboxClassNames = ['form-control input-sm validatebox-text'];
        if (mandatory)
            textboxClassNames.push('border border-danger')
        return <div style={{ display: 'grid', alignItems: 'center', justifyItems: 'center', gridTemplateColumns: '1fr auto auto', width: '-webkit-fill-available' }}>
            <input
                className={textboxClassNames.join(" ")}
                {...cardInputProps}
                value={maskedInput}
                autoComplete="disabled"
                name="validatebox-text"
                title=""
                 onInvalid={(e) =>{ 
                    e.target.setCustomValidity('Invalid Custom message')}}
                onInput={(e) =>{ 
                    e.target.setCustomValidity('')}}             
                style={{ paddingLeft: '3px' }}
                placeholder={placeholderMask} >
            </input>
            <span wit-title="Clear"
                onClick={onClear}
                onMouseEnter={e => {
                    $(e.target).removeClass('text-muted').addClass('text-dark');
                }} onMouseLeave={e => {
                    $(e.target).removeClass('text-dark').addClass('text-muted');
                }} className="fa fa-times text-muted pointer" style={{ marginLeft: '-15px', fontSize: '10px' }} ></span>
            <div>
                <img className="form-control mn pn" style={{ width: '55px' }} src={__icon} alt=""></img>
            </div>
        </div>;

    }
    render() {
        return this.renderInput();
    }
}