import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './general-view/App';
import * as serviceWorker from './serviceWorker';
import GroupBooking from './group-booking-view/GroupBooking'
import HotelPay from './hotel-pay-view/HotelPay'

import {
  BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter,
  } from "react-router-dom";

  const routing = (
    <Router basename="/">
       <Switch>        
        <Route path="/" exact component={App} />
        <Route path="/group-event" exact component={GroupBooking} />
        <Route path="/hotel-payment" exact component={HotelPay} />
        <Route path="*" component={()=>{return<div  className="p-5 text-center "><h1 className="text-danger">404</h1><h1 className="text-danger">Page not found</h1><h6><a href="/">Home</a></h6></div>}} /> 
      </Switch> 
        
    </Router>
  )
ReactDOM.render(routing , document.getElementById('root'));

serviceWorker.unregister();
