import { dispatch } from '../dispatcher';
import * as constants from '../constants';
import reservationsData from '../reservation-data.json';
import Session from '../Session';
import {clearErrorState,raiseValidationError} from '../reducer'
import {ApiService} from '../service'
const stepNavigation = async(params, ref) => {
       let localState = ref.state;
    if (params === "PAYMENT")
    {
        localState = dispatch(ref.state, { type: constants.requestPayment, view: constants.CARD_VIEW });
        ref.setState({ ...localState });
    }
    else if (params === "RESERVATIONLIST")
    {
        localState = dispatch(ref.state, { type: constants.backToView, view: constants.RESERVATION_VIEW });
        ref.setState({ ...localState });
    }
    else if (params === "FINAL")
    {
        localState=dispatch(ref,{ type: constants.requestCardDetails });  
        ref.setState({ ...localState });
        let {PropertyCode,RefNum,RsvnDtl,RecType}=ref.inputParams;
       
        let requestParams=Object.assign({},{PropertyCode,RefNum,RsvnDtl,RecType,Arrival:'',Departure:''},ref.cardParameter);
        let apiRequest={RsvnGrntyDetails:[]};
        apiRequest.RsvnGrntyDetails.push(requestParams);
        
        if(ref.isTestMode){        
            // localState=dispatch(ref,{ type: constants.receiveCardDetails,  response:{Status:'Success'},view: constants.FINAL_VIEW });
            // new Session().append("SAVED-GP",reservation.Reservations.filter(p=>p.selected));
            // ref.setState({ ...localState });
            // return;
        }

        ApiService("UpdateGuaranty", apiRequest, (data) => {
            let response = data || {Status:'Failed'};
            let view= constants.FINAL_VIEW;
            if(response.Status.toUpperCase()!="SUCCESS")
                view= constants.CARD_VIEW;
            localState=dispatch(ref,{ type: constants.receiveCardDetails, response,view: view });            
            ref.setState({ ...localState });

        }, (error) => {
            localState=dispatch(ref,{ type: constants.errorCardService, response:{Status:'Failed'},view: constants.CARD_VIEW  });
            ref.setState({ ...localState });
        });   

        
        
    }        
   
}



export { stepNavigation,  raiseValidationError,clearErrorState }