import React from 'react';

const SuccessView = (ref) => {   
    return <div className="row p-5">
        <div className="col-12">
            <div className="container p-5">
                <div className="card card-small user-details mb-4">
                    <div className="card-header p-0">

                    </div>
                    <div className="card-body p-5">
                        <div style={{ padding: '5rem' }}>
                            <h4 className="text-center m-0 mt-2">{ref.thankYouMessage}</h4>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default SuccessView;