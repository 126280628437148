export default class Session extends Map {
    set(id, value) {
      if (typeof value === 'object') value = JSON.stringify(value);
      sessionStorage.setItem(id, value);
    }
    append(id, value){
        let vals=this.get(id);
        if(vals)
        {
          let arrayNew=[...vals,...value];
          this.set(id,arrayNew);
        }
        else
        {
          this.set(id,value);
        }
       
    }
    get(id) {
      const value = sessionStorage.getItem(id);
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    }
  }