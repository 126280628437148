import React,{useState} from 'react'
import { Spinner } from 'reactstrap';

const Loader = (props) => {
    return (
      <div style={{width:'100%',height:'100%',zIndex:'999',backgroundColor:'rgba(193, 220, 186, 0.70)',marginLeft:'-15px'}} className="d-flex align-items-center justify-content-center position-fixed">       
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
      </div>
    );
  }
  
  export default Loader;