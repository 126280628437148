export const requestBedroomDetails="REQUEST-BEDROOM-DETAILS";
export const receiveBedroomDetails="RECIEVE-BEDROOM-DETAILS";
export const receiveCardDetails="RECIEVE-CARD-SAVE-DETAILS";
export const requestCardDetails="REQUEST-CARD-SAVE-DETAILS";
export const requestSetActiveReservation="REQUEST-SET-ACTIVE_RESERVATION";
export const responseSetActiveReservation="RESPONSE-SET-ACTIVE_RESERVATION";
export const requestPayment="REQUEST-PAYMENT";
export const requestValidations="REQUEST-VALIDATIONS";
export const errorBedroomDetails="ERROR-BEDROOM-DETAILS";
export const errorCardService="ERROR-CARD-SERVICE";
export const backToView="BACK-TO-VIEW";
export const clearBedRoomListError="CLEAR-ERROR-BEDROOM-LIST";
export const requestStaticData="REQUEST-STATIC-DATA";
export const receiveStaticData="RECIEVE-STATIC-DATA";

export const RESERVATION_VIEW="RESERVATION_VIEW";
export const CARD_VIEW="CARD_VIEW";
export const FINAL_VIEW="FINAL_VIEW";


export const API_URL = "http://pms.gghsystems.com/GGHAPI/api";