import React from 'react';
import WITCardInput from '../WITCardInput';
import Loader from '../Loader';
import SuccessView from '../SuccessVIew'
import { stepNavigation,   raiseValidationError } from './HotelPay.single-state-reducer'
import * as constants from '../constants';
import {loadStaticData} from '../service'
import moment from 'moment';
import Session from '../Session';
class HotelPay extends React.Component {
    state = {
        reservation: null,
        requestParams: {},
        isLoading: false,
        view: '',
        validations: {},
        cardServerResponse: {},
        staticData:{}
    }
    isTestMode = false;
    sessionStore = new Session();
    inputParams = {
        RefNum: '',
        RsvnDtl: '0',
        LastName: '',
        PropertyCode: "",
        RecType:'O'
    }
    thankYouMessage="Thank you for submitting the card information.";
    cardParameter = {
        GuarantyCard: {
            CardType: '',
            IssueDate: '',
            ExpDate: '',
            HolderName: '',
            CCNumber: '',
            CvvNum: ''
        },
        Address1: '',
        Address2: '',
        City: '',
        Country: '',
        PostCode: '',
        ContactNo: '',
        Remarks:'',
        Email:'',
        GuestName:''
    }
    componentDidMount(){
        loadStaticData(this);
    }
    raiseValidationState = (params, ref) => {
        let validations = Object.assign({}, params, ref);
        return validations;
    }
    handleCardValidation = () => {
        let ref = this;
        let validations = {};       
        if (ref.inputParams.PropertyCode.trim().length <= 0) {
            validations = this.raiseValidationState({ Hotel: { error: true, errorText: 'Please select hotel' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ Hotel: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.Remarks.trim().length <= 0) {
            validations = this.raiseValidationState({ Remarks: { error: true, errorText: 'Please specify what would you like to pay or guarantee' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ Remarks: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.GuarantyCard.HolderName.trim().length <= 0) {
            validations = this.raiseValidationState({ HolderName: { error: true, errorText: 'Please enter card holder name.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ HolderName: { error: false, errorText: '' } }, validations)
        }

        if (!ref.cardParameter.GuarantyCard.CardType || ref.cardParameter.GuarantyCard.CardType == '' || !ref.cardParameter.GuarantyCard.CCNumber || ref.cardParameter.GuarantyCard.CCNumber == '') {
            validations = this.raiseValidationState({ cc: { error: true, errorText: 'Please enter valid card details.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ cc: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.GuarantyCard.CvvNum.trim().length <= 0) {
            validations = this.raiseValidationState({ CVV: { error: true, errorText: 'Please enter cvv number.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ CVV: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.GuarantyCard.ExpDate.trim().length <= 0) {
            validations = this.raiseValidationState({ Expiry: { error: true, errorText: 'Please enter valid expiry date (MM/YY).' } }, validations)
        }
        else {
            if(moment(this.state.staticData.ServerDate,'DDMMMYYYY',false).isAfter(moment(ref.cardParameter.GuarantyCard.ExpDate.trim(),'DDMMMYYYY',false))){
                validations = this.raiseValidationState({ Expiry: { error: true, errorText: 'Please enter valid expiry date (MM/YY).' } }, validations)    
            }
            else
                validations = this.raiseValidationState({ Expiry: { error: false, errorText: '' } }, validations)
        }

        if (ref.cardParameter.Address1.trim().length <= 0) {
            validations = this.raiseValidationState({ Address: { error: true, errorText: 'Please enter address 1.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ Address: { error: false, errorText: '' } }, validations)
        }

        if (ref.cardParameter.City.trim().length <= 0) {
            validations = this.raiseValidationState({ City: { error: true, errorText: 'Please enter City.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ City: { error: false, errorText: '' } }, validations)
        }

        if (ref.cardParameter.Country.trim().length <= 0) {
            validations = this.raiseValidationState({ Country: { error: true, errorText: 'Please enter country.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ Country: { error: false, errorText: '' } }, validations)
        }

        if (ref.cardParameter.PostCode.trim().length <= 0) {
            validations = this.raiseValidationState({ PostCode: { error: true, errorText: 'Please enter postcode.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ PostCode: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.ContactNo.trim().length <= 0) {
            validations = this.raiseValidationState({ ContactNo: { error: true, errorText: 'Please enter contact no.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ ContactNo: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.Email.trim().length > 0) {
            
            const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            var testValue=regex.test(ref.cardParameter.Email.trim());
            if(!testValue)
                validations = this.raiseValidationState({ Email: { error: true, errorText: 'Please enter valid email.' } }, validations);
            else
                validations = this.raiseValidationState({ Email: { error: false, errorText: '' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ Email: { error: false, errorText: '' } }, validations)
        }
        if (ref.cardParameter.GuestName.trim().length <= 0) {
            validations = this.raiseValidationState({ GuestName: { error: true, errorText: 'Please enter guest name.' } }, validations)
        }
        else {
            validations = this.raiseValidationState({ GuestName: { error: false, errorText: '' } }, validations)
        }
        let hasError = false;
        if (validations) {
            for (let key in validations) {
                if (validations[key].error) {
                    hasError = true;
                    break;
                }
            }
        }
        return { hasError, validations };
        //raiseValidationError(validations,ref);

    }
    hasCardError = () => {
        let hasError = false;
        if (this.state.validations) {
            for (let key in this.state.validations) {
                if (this.state.validations[key].error) {
                    hasError = true;
                    break;
                }
            }
        }
        return hasError;
    }
    checkAnyReservationSelected() {
        return this.state.reservation.Reservations.filter(p => p.selected).length > 0;
    }
    handleChangeValidation = () => {
        if (this.hasCardError()) {
            this.handleCardValidation();
        }
    }
   
    render() {
        return <div className="container-fluid">
            {this.state.isLoading && <Loader></Loader>}
            <div className="row">
                <main className="main-content col-lg-12 col-md-12 col-sm-12 p-0 ">
                    <div className="main-navbar sticky-top bg-white d-flex align-items-center justify-content-center">                       
                        <img src="/Logo.png" style={{width:'70px',margin:'0.5rem'}}></img>
                    </div>
                    <div className="main-content-container container-fluid px-4">
                        <div className="row ">
                            <div className="col-12">
                                <div className="container p-3 d-flex justify-content-center flex-column w-100">                                   
                                    {(this.state.view === "" || this.state.view === constants.CARD_VIEW) && renderCardView(this)}
                                    {this.state.view === constants.FINAL_VIEW && SuccessView(this)}
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </div>
    }
}


function renderCardView(ref) {
    return <div className="row align-items-center justify-content-center">
        <div className="col-md-10 col-lg-8 col-sm-12 col-xs-12">
            <div className="container ">
            <form autoComplete="off" >
                <div className="card card-small user-details mb-4">
                    <div className="card-header p-1 text-center">
                        {ref.state.cardServerResponse && ref.state.cardServerResponse.Status && ref.state.cardServerResponse.Status.toUpperCase != "SUCCESS" && <span className="text-danger">Failed ! Please try again.</span>}
                    </div>
                    <div className="card-body pt-5">
                        <h4 className="text-center m-0 mt-2">Guarantee Information</h4>
                        <p className="text-center text-light m-1 mb-2">Hotel Payment</p>

                       
                                <form className="mt-3" >
                                <div className="form-group">
                                <label >Hotel</label>
                                <select className="form-control" 
                                    title=""                                    
                                    onChange={e => {
                                         ref.inputParams.PropertyCode = e.target.value;
                                         ref.handleChangeValidation();
                                    }}>
                                    <option value="">Select Hotel</option>
                                    {ref.state.staticData && ref.state.staticData.Hotels && ref.state.staticData.Hotels.map((item, index) => {
                                        return <option key={index} value={item.PropertyCode}>{item.PropName}</option>
                                    })}
                                </select>
                                {ref.state.validations.Hotel && ref.state.validations.Hotel.error && <span className="text-danger">{ref.state.validations.Hotel.errorText}</span>}
                            </div>
                            </form>
                        <form className="mt-3" >
                                <div className="form-group">
                                <label >Please specify what would you like to pay or guarantee</label>
                                <input type="text" className="form-control" onChange={e => {
                                    ref.cardParameter.Remarks = e.target.value;
                                    ref.handleChangeValidation();
                                }} autoComplete="disabled"  placeholder="Pay / Guarantee?" maxLength="1000" />
                                {ref.state.validations.Remarks && ref.state.validations.Remarks.error && <span className="text-danger">{ref.state.validations.Remarks.errorText}</span>}
                            </div>
                            <div className="form-group">
                                <label >Card Holder</label>
                                <input type="text" className="form-control" onChange={e => {
                                    ref.cardParameter.GuarantyCard.HolderName = e.target.value;
                                    ref.handleChangeValidation();
                                }} autoComplete="disabled"  placeholder="Card Holder" maxLength="100" />
                                {ref.state.validations.HolderName && ref.state.validations.HolderName.error && <span className="text-danger">{ref.state.validations.HolderName.errorText}</span>}
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label >Card No</label>
                                    <WITCardInput onChange={e => {
                                        ref.cardParameter.GuarantyCard.CardType = e.type;
                                        ref.cardParameter.GuarantyCard.CCNumber = e.value;
                                        ref.handleChangeValidation();
                                    }} />
                                    {ref.state.validations.cc && ref.state.validations.cc.error && <span className="text-danger">{ref.state.validations.cc.errorText}</span>}
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label >Expiry</label>
                                    <input type="text" className="form-control" 
                                        onChange={e => {
                                            if (e.target.validity.valid) {
                                                let data = moment(e.target.value, 'MMYY', false);
                                                if (data.isValid())
                                                    ref.cardParameter.GuarantyCard.ExpDate = data.format('DD MMM YYYY');// data.format('DD MMM YYYY');
                                                else
                                                    ref.cardParameter.GuarantyCard.ExpDate = "";
                                            }
                                            else
                                                ref.cardParameter.GuarantyCard.ExpDate = '';

                                            ref.handleChangeValidation();
                                        }}
                                        autoComplete="disabled" pattern="\d{1,2}/\d{1,2}" placeholder="MM/YY" />
                                    {ref.state.validations.Expiry && ref.state.validations.Expiry.error && <span className="text-danger">{ref.state.validations.Expiry.errorText}</span>}
                                </div>
                                <div class="form-group col-md-6">
                                    <label >CVV</label>
                                    <input type="password" className="form-control"
                                        onChange={e => {
                                            ref.cardParameter.GuarantyCard.CvvNum = e.target.value;
                                            ref.handleChangeValidation();
                                        }}
                                         autoComplete="disabled" placeholder="CVV" maxLength="4" />
                                    {ref.state.validations.CVV && ref.state.validations.CVV.error && <span className="text-danger">{ref.state.validations.CVV.errorText}</span>}
                                </div>
                            </div>
                            <div className="form-group">
                                <label >Address</label>
                                <input type="text" className="form-control"
                                maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.Address1 = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                    autoComplete="disabled" placeholder="1234 Main St" />
                                {ref.state.validations.Address && ref.state.validations.Address.error && <span className="text-danger">{ref.state.validations.Address.errorText}</span>}
                            </div>
                            <div className="form-group">
                                <label >Address 2</label>
                                <input type="text" className="form-control"
                                maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.Address2 = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                     autoComplete="disabled" placeholder="Apartment, studio, or floor" />

                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label >City</label>
                                    <input type="text" className="form-control"
                                    maxLength="200"
                                        onChange={e => {
                                            ref.cardParameter.City = e.target.value;
                                            ref.handleChangeValidation();
                                        }}
                                         autoComplete="disabled" />
                                    {ref.state.validations.City && ref.state.validations.City.error && <span className="text-danger">{ref.state.validations.City.errorText}</span>}
                                </div>
                                <div className="form-group col-md-4">
                                    <label >Country</label>
                                    <input type="text" className="form-control"
                                    maxLength="100"
                                        onChange={e => {
                                            ref.cardParameter.Country = e.target.value;
                                            ref.handleChangeValidation();
                                        }}
                                         autoComplete="disabled" />
                                    {ref.state.validations.Country && ref.state.validations.Country.error && <span className="text-danger">{ref.state.validations.Country.errorText}</span>}
                                </div>
                                <div className="form-group col-md-2">
                                    <label >Postcode</label>
                                    <input type="text"
                                     maxLength="25"
                                        onChange={e => {
                                            ref.cardParameter.PostCode = e.target.value;
                                            ref.handleChangeValidation();
                                        }}
                                        className="form-control" autoComplete="disabled"  />
                                    {ref.state.validations.PostCode && ref.state.validations.PostCode.error && <span className="text-danger">{ref.state.validations.PostCode.errorText}</span>}
                                </div>
                            </div>
                            <div className="form-row">
                            <div className="form-group col-md-6">
                                <label >Contact No</label>
                                <input type="text" className="form-control"
                                    maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.ContactNo = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                     autoComplete="disabled" placeholder="Contact Number" />
                                {ref.state.validations.ContactNo && ref.state.validations.ContactNo.error && <span className="text-danger">{ref.state.validations.ContactNo.errorText}</span>}
                            </div>
                            <div className="form-group col-md-6">
                                <label >Email</label>
                                <input type="text" className="form-control"
                                    maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.Email = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                     autoComplete="disabled" placeholder="Email" />
                                {ref.state.validations.Email && ref.state.validations.Email.error && <span className="text-danger">{ref.state.validations.Email.errorText}</span>}
                            </div>
                            </div>
                            <div className="form-group">
                                <label >Guest Name</label>
                                <input type="text" className="form-control"
                                    maxLength="200"
                                    onChange={e => {
                                        ref.cardParameter.GuestName = e.target.value;
                                        ref.handleChangeValidation();
                                    }}
                                     autoComplete="disabled" placeholder="Mr. John Doe" />
                                {ref.state.validations.GuestName && ref.state.validations.GuestName.error && <span className="text-danger">{ref.state.validations.GuestName.errorText}</span>}
                            </div>
                            <div className="row align-items-center justify-content-center">
                                {/* <button type="button" class="m-2 btn btn-warning " onClick={e => { stepNavigation('RESERVATIONLIST', ref) }} >Back</button> */}
                                <button class="m-2 btn btn-sm btn-info mr-1" type="button" onClick={e => {
                                    let status = ref.handleCardValidation();
                                    if (status.hasError) {
                                        raiseValidationError(status.validations, ref);
                                    }
                                    else {
                                        stepNavigation('FINAL', ref)
                                    }
                                }}>Continue</button>
                            </div>
                        </form>
                    </div>
                </div>
            </form>
            </div>
        </div>
    </div>
}


export default HotelPay;
